import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { theme } from '../styles'
import { Layout, Articles, Wrapper, Subtitle, SEO } from '../components'
import website from '../../config/website'

import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

const Hero = styled.section`
  background-color: ${theme.colors.black};
  padding-top: 10rem;
  padding-bottom: 4rem;
  h2 {
    color: ${theme.colors.bg};
    font-family: 'TeleGroteskNext-Thin';
    font-weight: 300;
    font-size: 3rem;
  }
`

const Headline = styled.p`
  color: ${theme.colors.greyLight};
  font-size: 1.25rem;
  a {
    font-style: normal;
    font-weight: normal;
  }
`

const CatWrapper = Wrapper.withComponent('main')

const Category = ({
  pageContext: { category },
  data: {
    posts: { edges, totalCount },
  },
  location,
}) => {
  const filtered = edges.filter(edge => {
    return edge.node.data.categories.some(cat =>
      cat.category.document ? cat.category.document.data.name === category : false
    )
  })
  return (
    <Layout>
      <Amplitude
        eventProperties={{
          scope: ['category'],
          title: `Category: ${category}`,
          url: location.pathname,
        }}
      >
        <LogOnMount eventType="category view" />
        <SEO title={`Category: ${category} | ${website.titleAlt}`} pathname={location.pathname} />
        <Hero>
          <Wrapper>
            <Headline>Publicaciones etiquetadas como</Headline>
            <h2>{category}</h2>
          </Wrapper>
        </Hero>
        <CatWrapper id={website.skipNavId}>
          <Subtitle style={{ marginTop: '4rem' }}>
            {filtered.length} {filtered.length === 1 ? 'publicación' : 'publicaciones'}{' '}
            {filtered.length === 1 ? 'etiquetada' : 'etiquetadas'} como "{category}"
          </Subtitle>
          <Articles posts={filtered} />
        </CatWrapper>
      </Amplitude>
    </Layout>
  )
}

export default Category

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    posts: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query CategoryPage {
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }) {
      totalCount
      edges {
        node {
          uid
          type
          data {
            title {
              text
            }
            date(formatString: "DD/MM/YYYY")
            description
            featured_image {
              alt
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500, maxHeight: 300, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    type
                    slugs
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
